<template>
    <v-card color="#F6F9FC" flat class="rounded-0 pt-6">
        <v-container class="px-0">
            <v-row>
                <v-col cols="12" lg="3">
                    <DashboardSidebar :buyedTotal="buyedTotal" :selfTotal="selfTotal"></DashboardSidebar>
                </v-col>
                <v-col cols="12" lg="9">
                    <v-card flat class="pa-4">
                        <div class="d-flex justify-space-between align-center">
                            <p class="mb-0 text-body-1 font-weight-medium">{{ $t('已购买模版') }}</p>
                            <!-- <v-btn depressed color="rgba(115, 49, 255, 0.10)" class="primary--text">{{ $t('一键应用到当前店铺')
                            }}</v-btn> -->
                        </div>
                        <v-divider class="my-4"></v-divider>
                        <v-row v-if="mylist.length > 0">
                            <v-col cols="6" md="4" sm="4" class="" v-for="(mitem, midx) in mylist" :key="midx">
                                <v-hover v-slot="{ hover }">
                                    <v-card flat outlined class="rounded-lg">

                                        <v-img :src="mitem.cover_img" alt="" class="rounded-lg">
                                            <v-chip color="#e1f6e0" class="success--text used-label" small
                                                v-if="mitem.is_exists == 1" label>{{ $t('当前店铺应用版本') }}：v{{ mitem.version
                                                }}</v-chip>
                                            <v-scroll-y-transition>
                                                <v-card color="rgba(0, 0, 0, 0.60)" flat v-if="hover" height="100%"
                                                    class="d-flex flex-column align-center justify-center">
                                                    <v-btn color="#fff" class="primary--text mb-2"
                                                        v-if="mitem.is_exists == 0" @click="touse(mitem.themes_id)">{{
                        $t('应用到当前店铺')
                    }}</v-btn>
                                                    <v-btn color="#fff" text
                                                        :to="'/template-detail?themes_id=' + mitem.themes_id">{{
                        $t('查看模版详情')
                    }}</v-btn>
                                                </v-card>

                                            </v-scroll-y-transition>
                                        </v-img>
                                        <div class="pa-3 pt-0">
                                            <p class="text-subtitle-1 mb-0 mt-3">{{ mitem.name }}</p>
                                            <p class="mb-0 ">
                                                <v-btn x-small depressed v-if="mitem.is_new_version == 1" color="danger"
                                                    dark @click="updateTheme(mitem)"><small>{{ $t('更新版本') }}：v{{
                        mitem.new_version }}</small></v-btn>
                                            </p>


                                        </div>

                                    </v-card>
                                </v-hover>
                            </v-col>

                        </v-row>
                        <v-card v-else flat class="text-center">
                            <img src="@/assets/images/template/no-buyed.png" alt="">
                            <p class="grey--text">{{ $t('还未购买模版') }}</p>
                        </v-card>
                    </v-card>
                    <v-card flat class=" mt-4">
                        <v-tabs background-color="#F2F2F2" center-active centered grow show-arrows
                            slider-color="transparent">
                            <v-tab v-for="(mitem, midx) in mobanlist" :key="midx" class="text-subtitle-1"
                                active-class="white" @click="tochangeSelf(mitem.val)">{{ mitem.text }}</v-tab>

                        </v-tabs>
                        <div class="pa-4">
                            <!-- <v-btn depressed color="rgba(115, 49, 255, 0.10)" class="primary--text mb-4"
                                v-if="selflist.length > 0">{{ $t('一键应用到当前店铺')
                                }}</v-btn> -->
                            <v-row v-if="selflist.length > 0">
                                <v-col cols="6" md="4" sm="4" class="" v-for="(sitem, sidx) in selflist" :key="sidx">
                                    <v-hover v-slot="{ hover }">
                                        <v-card flat outlined class="rounded-lg">

                                            <v-img :src="sitem.cover_img" alt="" class="rounded-lg">
                                                <v-scroll-y-transition>
                                                    <v-card color="rgba(0, 0, 0, 0.60)" flat v-if="hover" height="100%"
                                                        class="d-flex flex-column align-center justify-center">
                                                        <v-btn color="#fff" class="primary--text mb-2"
                                                            @click="openDialog(sitem)" v-if="sitem.themes_status == 0">
                                                            <span v-if="sitem.is_sale == 0">
                                                                {{ $t('发布模版到市场') }}
                                                            </span>
                                                            <span v-else>
                                                                {{ $t('发布新版本') }}
                                                            </span>
                                                        </v-btn>
                                                        <v-btn color="#fff" class="primary--text mb-2" v-if="sitem.shop_id!=$store.state.defaultShopid"
                                                            @click="touseCustom(sitem.custom_themes_id)">{{
                        $t('应用到当前店铺')
                    }}</v-btn>
                                                        <v-btn color="primary" class="mb-2"
                                                            @click="delSelfmoban(sitem.custom_themes_id)">{{
                        $t('删除当前模版')
                    }}</v-btn>
                                                        <v-btn color="#fff" text v-if="sitem.themes_status != 0"
                                                            :to="'/template-detail-custom?custom_themes_id=' + sitem.custom_themes_id">{{
                        $t('查看模版详情') }}</v-btn>
                                                    </v-card>

                                                </v-scroll-y-transition>
                                            </v-img>
                                            <div class="pa-3">
                                                <p class="text-subtitle-1 mb-0">
                                                    <v-chip color="rgba(9, 187, 7, 0.10)" class="success--text" small
                                                        v-if="sitem.themes_status == 2" label>{{ $t('审核通过') }}</v-chip>
                                                    <v-chip color="rgba(255, 34, 34, 0.10)" class="danger--text" small
                                                        v-if="sitem.themes_status == 3" label>{{ $t('审核失败') }}</v-chip>
                                                    <v-chip color="rgba(255, 140, 34, 0.10)" class="warning--text" small
                                                        v-if="sitem.themes_status == 1" label>{{ $t('已发布') }}</v-chip>
                                                    <v-chip color="rgba(87, 139, 255, 0.10)" class="info--text" small
                                                        v-if="sitem.themes_status == 0" label>{{ $t('未发布') }}</v-chip>
                                                    {{ sitem.custom_name }}
                                                </p>
                                                <small  class="grey--text">
                                                    {{ $t('市场名称') }}：{{ sitem.name }}
                                                </small>
                                            </div>

                                        </v-card>
                                    </v-hover>
                                </v-col>

                            </v-row>
                            <v-card flat height="456" class="d-flex align-center justify-center flex-column" v-else>
                                <img src="@/assets/images/loading/wusousuo.png" alt="">
                                <p>{{ $t('无结果') }}</p>
                            </v-card>
                        </div>

                    </v-card>


                </v-col>
            </v-row>
        </v-container>
        <v-dialog v-model="releasedShow">
            <v-card width="700" flat v-if="releasedObj">
                <v-card-title class="text-h6" style="background-color: #F2ECFF;">{{ $t('发布模版') }}

                </v-card-title>
                <v-card max-height="800" class="overflow-y-auto" flat>
                    <v-card-text>
                        <v-card flat class="d-flex align-center pa-4 my-2" color="rgba(255,87,34,0.14)">
                            <v-icon color="deep-orange darken-1">mdi-information-outline</v-icon>
                            <p class="mb-0 ml-2">{{
                        $t('生成待发布模版，可在个人中心提交发布到模版市场，taoifyShop会尽快审核，审核通过后将上架到模版市场，其他人购买后，可赚佣金。审核结果请在个人中心的自定义模版中查看，大概需要1~2个工作日。')
                    }}</p>
                        </v-card>
                        <p class="black--text py-2 font-weight-bold">{{ $t('作品信息') }}</p>

                        <v-row>
                            <v-col cols="3">
                                <span>{{ $t('名称') }}</span>
                            </v-col>
                            <v-col cols="9">
                                <v-card flat height="40">
                                    <v-text-field class="ipt-style" outlined filled v-model="releasedObj.name" dense
                                        :placeholder="$t('模版名称')" :readonly="releasedObj.is_sale == 1"></v-text-field>
                                </v-card>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="3">
                                <span>{{ $t('版本号') }}</span>
                            </v-col>
                            <v-col cols="9">
                                <v-card flat height="40">
                                    <v-text-field class="ipt-style" outlined filled v-model="releasedObj.version" dense
                                        :placeholder="$t('数字')"></v-text-field>
                                </v-card>
                            </v-col>
                        </v-row>
                        <div>
                            <v-row>
                                <v-col cols="3">
                                    <span>{{ $t('描述') }}</span>
                                </v-col>
                                <v-col cols="9">
                                    <v-card flat>
                                        <v-textarea outlined filled v-model="releasedObj.des" dense height="100"
                                            hide-details :placeholder="$t('一些简单描述，帮助顾客更快了解模版信息')"></v-textarea>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="3">
                                    <span>{{ $t('封面') }}</span>
                                </v-col>
                                <v-col cols="9">
                                    <div class="d-flex align-center">
                                        <v-card flat color="#f0f0f0" width="220" height="120" outlined
                                            class="d-flex flex-column align-center justify-center upload-img mr-2">
                                            <v-icon color="#CAB0FF" size="40">mdi-plus-circle-outline</v-icon>
                                            <p class="px-2 mb-0 mt-2">{{ $t('支持jpg/gif/png格式,不超过5M') }}</p>
                                            <p class="px-2 mb-0 mt-2">{{ $t('尺寸建议：750*1334') }}</p>
                                            <input type="file" @change="onFileChange(...arguments, 1)">
                                        </v-card>
                                        <a :href="releasedObj.cover_img" target="_blank" v-if="releasedObj.cover_img">
                                            <img :src="releasedObj.cover_img" alt="" style="max-width: 150px;">
                                        </a>

                                    </div>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="3">
                                    <span>{{ $t('是否免费') }}</span>
                                </v-col>
                                <v-col cols="9">
                                    <v-card flat height="40">
                                        <v-radio-group v-model="releasedObj.type" row dense class="mt-0">
                                            <v-radio :label="$t('免费')" value="1"></v-radio>
                                            <v-radio :label="$t('付费')" value="2"></v-radio>
                                        </v-radio-group>

                                    </v-card>
                                </v-col>
                            </v-row>
                            <v-row v-if="releasedObj.type == 2">
                                <v-col cols="3">
                                    <span>{{ $t('售卖价格') }}</span>
                                </v-col>
                                <v-col cols="9">
                                    <v-card flat height="40">
                                        <v-text-field outlined filled v-model="releasedObj.price" dense
                                            prefix="￥"></v-text-field>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="3">
                                    <span>{{ $t('支持高分辨率') }}</span>
                                </v-col>
                                <v-col cols="9">
                                    <v-card flat height="40">
                                        <v-radio-group v-model="releasedObj.is_high_resolution" row dense class="mt-0">
                                            <v-radio :label="$t('支持')" value="1"></v-radio>
                                            <v-radio :label="$t('不支持')" value="0"></v-radio>
                                        </v-radio-group>

                                    </v-card>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="3">
                                    <span>{{ $t('可兼容浏览器') }}</span>
                                </v-col>
                                <v-col cols="9">
                                    <v-card flat>
                                        <v-textarea outlined filled v-model="releasedObj.support_browsers" dense
                                            height="100" hide-details
                                            :placeholder="$t('Safari、Opera、Chrome、Edge')"></v-textarea>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="3">
                                    <span>{{ $t('布局') }}</span>
                                </v-col>
                                <v-col cols="9">
                                    <v-card flat>
                                        <v-select outlined filled dense hide-details :items="layoutList"
                                            v-model="releasedObj.layout" :label="$t('请选择')"></v-select>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="3">
                                    <span>{{ $t('标签') }}</span>
                                    <br>


                                </v-col>
                                <v-col cols="9">
                                    <v-card flat class="mb-2">

                                        <v-card flat height="40">
                                            <v-text-field v-model="tagText" outlined filled dense
                                                @keyup.enter="addLocalpost"
                                                :label="$t('按Enter键保存和添加更多')"></v-text-field>
                                        </v-card>
                                        <v-card flat class="mt-2">
                                            <v-chip class="ma-2" close v-for="(item, idx) in releasedObj.themes_tag"
                                                :key="idx" @click:close="delLocalpost(idx)">{{ item }}</v-chip>
                                        </v-card>
                                    </v-card>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="3">
                                    <span>{{ $t('特点') }}</span>
                                </v-col>
                                <v-col cols="9">
                                    <v-card flat>
                                        <div class="d-flex" v-for="(fitem, fidx) in releasedObj.features" :key="fidx">

                                            <v-text-field outlined v-model="releasedObj.features[fidx]" dense filled
                                                :placeholder="$t('模版亮点')"></v-text-field>

                                            <v-btn @click="addFeature" text
                                                v-if="fidx == releasedObj.features.length - 1"><v-icon>mdi-plus-one</v-icon></v-btn>
                                            <v-btn @click="delFeature(fidx)" text
                                                v-if="releasedObj.features.length > 1"><v-icon>mdi-close</v-icon></v-btn>

                                        </div>


                                    </v-card>
                                </v-col>
                            </v-row>
                        </div>

                    </v-card-text>
                </v-card>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary darken-1" text @click="releasedShow = false">{{ $t('关闭') }}</v-btn>
                    <v-btn color="primary darken-1" text @click="postReleased">{{ $t('提交') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- 发布模板 -->
        <v-dialog v-model="selfObj.show" persistent width="450">
            <v-card>
                <v-card-title class="text-h6">
                    <v-icon color="#faad14" class="mr-2">mdi-information-outline</v-icon>
                    {{ $t('发布模版') }}
                </v-card-title>
                <v-card-text>{{ $t('申请发布模版，taoifyShop会尽快审核，请在个人中心查看结果。') }}</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="selfObj.show = false">{{ $t('我知道了') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>
<script>
import DashboardSidebar from '../../components/DashboardSidebar.vue'
export default {
    components: {
        DashboardSidebar
    },
    data() {
        return {
            mobanlist: [{ text: this.$t('全部自定义模版'), val: '-1' },
            { text: this.$t('待发布'), val: '0' },
            { text: this.$t('待审核'), val: '1' },
            { text: this.$t('审核通过'), val: '2' },
            { text: this.$t('审核未通过'), val: '3' }],
            mylist: [],
            postParam: {
                pay_status: 1,
                current_page: 1,
                page_number: 50
            },
            selfParam: {
                themes_status: '-1',
                current_page: 1,
                page_number: 20
            },
            selflist: [],//自定义模板
            selfObj: {
                show: false,
                themes_id: 0
            },
            buyedTotal: '',
            selfTotal: '',
            releasedObj: {
                custom_themes_id: '',
                type: '1',//1=免费，2=付费
                category: '',//类别
                name: "",//模板名称
                cover_img: "",//封面图
                price: "0",//价格
                des: "",//模板描述
                features: [''],//特点
                themes_info: [{
                    decoration_module_id: '',
                    content: ''
                }],//模板预览链接
                edit_type: 3,//1=保存，2=另存为，3=上传到自定义模板
                custom_type: 2,//上传到自定义模板时，传此参数 ，1=更新，2=新增
                is_high_resolution: '1',//支持高分辨率
                support_browsers: '',//可兼容浏览器
                layout: '',//布局
                themes_tag: [],//模版标签ID
                version: 1,//版本号
            },// 发布模版
            releasedShow: false,// 发布模版弹窗
            layoutList: [this.$t('自适应'), this.$t('仅支持PC'), this.$t('仅支持H5')],//布局选项
            tagText: ''
        }
    },
    mounted() {
        this.getMymoban();
        this.getSelfmoban();
    },
    methods: {
        getMymoban() {
            this.$api.template.order_list(this.postParam).then(res => {
                if (res.status && res.data.records) {
                    this.mylist = res.data.records;
                    this.buyedTotal = res.data.total;
                }
            })
        },
        //使用购买模版
        touse(id) {
            this.$api.template.use_themes({ themes_id: id }).then(res => {
                if (res.status) {
                    this.$Toast({ content: res.msg });
                    this.getMymoban()
                } else {
                    this.$Toast({ content: res.msg, type: 'error' });
                }
            })
        },
        //更新已购买模版版本号
        updateTheme(item) {
            this.$api.template.up_use_themes({ themes_order_id: item.themes_order_id }).then(res => {
                if (res.status) {
                    this.$Toast({ content: res.msg });
                    this.getMymoban()
                } else {
                    this.$Toast({ content: res.msg, type: 'error' });
                }
            })
        },
        //使用自定义模版
        touseCustom(id) {
            this.$api.template.use_custom_themes({ custom_themes_id: id }).then(res => {
                if (res.status) {
                    this.$Toast({ content: res.msg });
                    this.getMymoban()
                } else {
                    this.$Toast({ content: res.msg, type: 'error' });
                }
            })
        },

        //切换自定义模板状态
        tochangeSelf(type) {
            this.selfParam.themes_status = type;
            this.getSelfmoban();

        },
        getSelfmoban() {
            this.$api.template.themes_list(this.selfParam).then(res => {
                if (res.status && res.data.records) {
                    this.selflist = res.data.records;
                    this.selfTotal = res.data.total;
                }
            })
        },
        delSelfmoban(id) {
            this.$api.template.themes_del({ custom_themes_id: id }).then(res => {
                if (res.status) {
                    this.$Toast({ content: res.msg });
                    this.getSelfmoban();
                } else {
                    this.$Toast({ content: res.msg, type: 'error' });
                }
            })
        },

        //增加特性
        addFeature() {
            this.releasedObj.features.push('');
        },
        //删除特性
        delFeature(idx) {
            this.releasedObj.features.splice(idx, 1)
        },
        addLocalpost() {
            if (this.tagText == "") {
                return;
            }
            this.releasedObj.themes_tag.push(this.tagText);
            this.tagText = "";
        },
        //本地配送 删除一条邮编
        delLocalpost(idx) {
            this.releasedObj.themes_tag.splice(idx, 1);
        },

        //获取图片地址
        onFileChange(e) {
            this.webImageUrl = false;
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length) return;

            var formData = new FormData();
            formData.append("file", files[0]);
            this.createImage(formData);
        },
        //生成图片路径
        createImage(file_) {
            this.$api.public.uploadimgs(file_).then(res => {
                if (res.status) {
                    this.releasedObj.cover_img = res.data;
                }
            });
        },
        openDialog(item) {
            this.releasedObj.custom_themes_id = item.custom_themes_id;
            let item_ = JSON.parse(JSON.stringify(item));
            this.$api.template.themes_detail({ custom_themes_id: item.custom_themes_id }).then(res => {
                if (res.status) {
                    this.releasedObj = item_;
                    this.releasedObj.themes_info = res.data.content;
                    this.releasedObj.themes_tag = [];
                    if (res.data.themes_tag_info.length > 0) {
                        res.data.themes_tag_info.map(item => {
                            this.releasedObj.themes_tag.push(item.name)
                        })
                    }

                    this.releasedShow = true;
                }
            })

        },
        //发布模版
        postReleased() {
            this.$api.template.themes_release(this.releasedObj).then(res => {
                this.selfObj.show = false;
                if (res.status) {
                    this.$Toast({ content: res.msg });
                    this.releasedShow = false;
                    this.releasedObj = {
                        custom_themes_id: '',
                        type: '1',//1=免费，2=付费
                        category: '',//类别
                        name: "",//模板名称
                        cover_img: "",//封面图
                        price: "0",//价格
                        des: "",//模板描述
                        features: [''],//特点

                        themes_info: [{
                            decoration_module_id: '',
                            content: ''
                        }],//模板预览链接
                        edit_type: 3,//1=保存，2=另存为，3=上传到自定义模板
                        custom_type: 2,//上传到自定义模板时，传此参数 ，1=更新，2=新增
                        is_high_resolution: '1',//支持高分辨率
                        support_browsers: '',//可兼容浏览器
                        layout: '',//布局
                        themes_tag: [],//模版标签ID
                        version: "1",//版本号
                    }
                    this.selfObj.show = true;

                    // this.getSelfmoban();
                } else {
                    this.$Toast({ content: res.msg, type: 'error' });
                }

            })
        }
    }
}
</script>
<style>
.v-tab,
.v-btn {
    text-transform: inherit;
}

.upload-img {
    input {
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
    }

    img {
        position: absolute;
        width: 100%;
        height: 100%;
    }
}

.used-label {
    position: absolute;
    right: 10px;
    top: 10px;
}
</style>